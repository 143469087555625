import React, { useState, useEffect, useCallback } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
const googleLibraries = ["places"];

const GoogleAddressAutocomplete = ({ address, onAddressChange }) => {
  const [autocomplete, setAutocomplete] = useState(null);

  // Use useCallback to memoize handlePlaceChanged
  const handlePlaceChanged = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;

      let newAddress = {
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      };

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("route")) {
          newAddress.street = component.long_name;
        }
        if (types.includes("locality")) {
          newAddress.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          newAddress.state = component.long_name;
        }
        if (types.includes("postal_code")) {
          newAddress.zip = component.long_name;
        }
        if (types.includes("country")) {
          newAddress.country = component.long_name;
        }
      });

      onAddressChange(newAddress);  // Pass the updated address to parent component
    }
  }, [autocomplete, onAddressChange]);

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener("place_changed", handlePlaceChanged);
    }
  }, [autocomplete, handlePlaceChanged]);

  // Handle changes for text input fields
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    onAddressChange({ ...address, [name]: value }); // Update the address from input fields
  };

  return (
    <div>
      <h2 className="form-title">What is your address?</h2>
      <LoadScript googleMapsApiKey="AIzaSyAIsjcpbETWhTRAfNsbG5qoTPn5560z9JA" libraries={googleLibraries}>
        {/* Autocomplete attached to the street input */}
        <Autocomplete
          onLoad={(autoC) => setAutocomplete(autoC)}
          onPlaceChanged={handlePlaceChanged}
          options={{
            componentRestrictions: { country: "us" }, // Restrict to the United States
          }}
        >
          <div className="input-wrapper">
            <label>Street:</label>
            <input
              type="text"
              name="street"
              id="street"
              value={address.street}
              onChange={handleAddressChange}
              placeholder="Start typing street address..."
              className="input-field"
            />
          </div>
        </Autocomplete>

        {/* Text inputs for the other address components */}
        <div style={{ marginTop: "20px" }}>
          <div className="input-wrapper">
            <label>City:</label>
            <input
              type="text"
              name="city"
              id="city"
              value={address.city}
              onChange={handleAddressChange}
              className="input-field"
            />
          </div>
          <div className="input-wrapper">
            <label>State:</label>
            <input
              type="text"
              name="state"
              id="state"
              value={address.state}
              onChange={handleAddressChange}
              className="input-field"
            />
          </div>
          <div className="input-wrapper">
            <label>Zip Code:</label>
            <input
              type="text"
              name="zip"
              id="zip"
              value={address.zip}
              onChange={handleAddressChange}
              className="input-field"
            />
          </div>
          <div className="input-wrapper">
            <label>Country:</label>
            <input
              type="text"
              name="country"
              id="country"
              value={address.country}
              onChange={handleAddressChange}
              className="input-field"
              readOnly
            />
          </div>
        </div>
      </LoadScript>
    </div>
  );
};

export default GoogleAddressAutocomplete;
