// LandingScreen Component
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import axios from 'axios'; // Import axios
import Header from './components/Header';
import MainContent from './components/MainContent';
import ButtonContainer from './components/ButtonContainer';
import './assets/css/style.css';

// Enable sending cookies with requests
axios.defaults.withCredentials = true;

export const useUUID = () => {
  // Fallback UUID generation function
  const generateFallbackUUID = () => {
    const baseStr = [1e7] + -1e3 + -4e3 + -8e3 + -1e11;
    const replacer = (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16);
    return baseStr.replace(/[018]/g, replacer);
  };

  // UUID generation function
  const generateUUID = () => {
    if (typeof crypto.randomUUID === "function") {
      return crypto.randomUUID();
    } else {
      return generateFallbackUUID();
    }
  };

  return generateUUID;
};

const LandingScreen = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(null); // config for APIs
  const [clinicName, setClinicName] = useState(''); // Dynamic clinic name
  const [fullClinicName, setFullClinicName] = useState(''); // Original clinic name
  const navigate = useNavigate(); // To redirect the user
  const currentPage = 'home';

  const generateUUID = useUUID();
  // const [uuid, setUUID] = useState("");

  useEffect(() => {
    const storedUUID = localStorage.getItem("app-uuid");
  
    if (storedUUID) {
      // Use the existing UUID (no need to store it in state if not needed)
    } else {
      const newUUID = generateUUID();
      localStorage.setItem("app-uuid", newUUID);
      // No need to call setUUID if not using the uuid state
    }
  }, [generateUUID]);
  

  // console.log(localStorage.getItem("app-uuid"));

  // Fetch config.json
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, []);

  // Helper function to update clinicName based on device width
  const updateClinicNameBasedOnWidth = (fullClinicName) => {
    const width = window.innerWidth;

    if (width <= 480) {
      return fullClinicName.length > 12 ? `${fullClinicName.substring(0, 12)} ...` : fullClinicName;
    } else if (width <= 768) {
      return fullClinicName.length > 18 ? `${fullClinicName.substring(0, 18)} ...` : fullClinicName;
    } else if (width <= 1024) {
      return fullClinicName.length > 25 ? `${fullClinicName.substring(0, 25)} ...` : fullClinicName;
    } else if (width <= 1366) {
      return fullClinicName.length > 35 ? `${fullClinicName.substring(0, 35)} ...` : fullClinicName;
    } else if (width <= 1920) {
      return fullClinicName.length > 45 ? `${fullClinicName.substring(0, 45)} ...` : fullClinicName;
    }
    return fullClinicName; // Default case if no condition matches
  };

  // Fetch data from the API
  useEffect(() => {
    if (!config) return; // Wait until config is available

    const urlPath = window.location.pathname;
    const id = urlPath.split('/').pop(); // Get the last segment of the URL

    const landingUrl = config.API_LANDING_SCREEN_ANONYMOUS; // Read from config.json
    const apiUrl = `${landingUrl}${id}`;

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        const responseCode = data?.RESPONSE_CODES[0];
        const clinicInfo = data?.RESPONSE?.clinicInfo;
        const fullClinicName = clinicInfo?.clinicName || '';

        setFullClinicName(fullClinicName); // Store the original clinic name

        // Update clinic name based on device width
        const updatedClinicName = updateClinicNameBasedOnWidth(fullClinicName);
        clinicInfo.clinicName = updatedClinicName; // Modify the clinicName

        if (responseCode === 'C_LANDING_ANON') {
          setData(data); // Render anonymous landing screen
        } else if (responseCode === 'C_LANDING_LOGGEDIN') {
          // Modify the clinicName before navigating to the dashboard
          navigate('/dashboard', { state: { dashboardData: data, fullClinicName:fullClinicName } }); // Redirect to dashboard screen
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('API or Web service Unavailable!');
      });
  }, [config, navigate]);

  // Update clinic name dynamically based on viewport width
  useEffect(() => {
    if (data) {
      const clinicInfo = data?.RESPONSE?.clinicInfo;
      const fullClinicName = clinicInfo?.clinicName || '';

      const updatedClinicName = updateClinicNameBasedOnWidth(fullClinicName);
      setClinicName(updatedClinicName);

      // Define the resize event listener
      const handleResize = () => {
        const updatedClinicNameOnResize = updateClinicNameBasedOnWidth(fullClinicName);
        setClinicName(updatedClinicNameOnResize);
      };

      window.addEventListener('resize', handleResize); // Update on window resize
      return () => window.removeEventListener('resize', handleResize); // Cleanup
    }
  }, [data]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!data) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
      </div>
    );
  }

  const clinicId = window.location.pathname.split('/').pop();
  const clinicInfo = data?.RESPONSE?.clinicInfo;
  const logoUrl = clinicInfo?.smallLogo;
  const emergencyMessage = clinicInfo?.emergencyMsg;

  return (
    <section className="container">
      <Header 
        emergencyMessage={emergencyMessage} 
        logoUrl={logoUrl} // Pass logoUrl to Header
        clinicName={clinicName} // Pass clinicName as a string
        fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      <MainContent 
        clinicName={clinicName} // Pass clinicName as a string
        services={data?.RESPONSE?.clinicLandingInfo?.landingForm?.form} 
        clinicId={clinicId}
        logoUrl={logoUrl} 
        emergencyMessage={emergencyMessage}
        fullClinicName={fullClinicName} 
      />

      <ButtonContainer 
        id={clinicId} 
        logoUrl={logoUrl} 
        emergencyMessage={emergencyMessage} 
        clinicName={clinicName}
        currentPage={currentPage}
        fullClinicName={fullClinicName}
      />
    </section>
  );
};

export default LandingScreen;
