// src/PatientScreen/components/NoCardsPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import UserGreeting from './UserGreeting';
import NavigationMenu from './NavigationMenu';

const NoCardsPage = () => {
  const { state } = useLocation();

  const {
    clinicId,
    patientFirstName,
    patientAvatar,
    patientNotifications,
    visitName, // Access passed visitName
    logoUrl,
    clinicName,
    fullClinicName
  } = state || {};

  return (
    <section className="container">
      
      {/* Header Section */}
      <Header 
        logoUrl={logoUrl} // Pass logoUrl to Header
        clinicName={clinicName} // Pass clinicName as a string
        fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      {/* User Greeting */}
      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
        />
      </section>

      {/* New Payment Section */}
      <div className="payment-container">
        <div className="plan-card">
          <div className="plan-header">
            <div className="plan-title">{visitName}</div> {/* Use visitName */}
            <img
              loading="lazy"
              src="../assets/images/tick.svg"
              className="info-icon"
              alt=""
            />
          </div>
          <div className="plan-body">
            <div className="price-container">
              <div className="price">$735</div>
            </div>
          </div>
        </div>
        <div className="payment-section">
          <div className="payment-header">
            <h2 className="payment-title">Add Payment Method</h2>
            <div className="action-container">
              <button className="add-card-button" tabIndex="0">
                Add card
              </button>
            </div>
          </div>
          <div className="payment-content"></div>
        </div>
      </div>

      {/* Navigation Menu */}
      <footer>
        <NavigationMenu clinicId={clinicId} />
      </footer>
    </section>
  );
};

export default NoCardsPage;
