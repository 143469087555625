// src/PatientScreen/components/UserGreeting.js

import React from "react";
import { useNavigate } from "react-router-dom";

const UserGreeting = ({ patientName, avatar, patientNotifications, clinicId, welcome }) => {
  const navigate = useNavigate();
  let welcomeText = '';
  if(welcome)
    welcomeText = ', Welcome back.';

  const handleNotificationsClick = () => {
    navigate("/notifications", {
      state: { notificationsData: patientNotifications, clinicId },
    });
  };

  return (
    <header className="header-section">
      <div className="user-greeting">
        <img src={avatar} alt="User avatar" className="user-avatar" />
        <p className="greeting-text">Hi {patientName}{welcomeText}</p>
      </div>
      <div className="action-button">
        <nav className="button-wrapper">
          <button
            className="nav-button"
            aria-label="Navigation menu"
            onClick={handleNotificationsClick}
          >
            <img src="/assets/images/notification.svg" alt="" className="nav-icon" />
          </button>
        </nav>
      </div>
    </header>
  );
};

export default UserGreeting;
