// src/AnonymousScreen/components/Header.js
import React, { useState } from 'react';
import Logo from './Logo';
import EmergencyBanner from './EmergencyBanner';

const Header = ({ emergencyMessage, logoUrl, showEmergencyBanner = true, clinicName, fullClinicName }) => {

  const [showTooltip, setShowTooltip] = useState(false); // Tooltip visibility
  const [tooltipTimeout, setTooltipTimeout] = useState(null); // Timeout reference

  // Handle tooltip visibility with timeout
  const handleClickTooltip = () => {
    setShowTooltip(true); // Show tooltip immediately on click
    
    // Clear any existing timeout to prevent multiple timeouts from stacking
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }
  
    // Set a new timeout to hide the tooltip after 3 seconds
    const timeout = setTimeout(() => {
      setShowTooltip(false); // Hide the tooltip after 3 seconds
    }, 3000);
  
    setTooltipTimeout(timeout); // Store the timeout ID for future clearing
  };
  

  return (
  <header className="header">
    {showEmergencyBanner && emergencyMessage && <EmergencyBanner emergencyMessage={emergencyMessage} />}
    <div className="header-content">
      <Logo logoUrl={logoUrl} />
      <h1 className="clinic-name">
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onClick={handleClickTooltip} // Use new handleClickTooltip for click event
          style={{ position: 'relative', display: 'inline-block' }}
        >
          {clinicName}
          {showTooltip && (
            <div className="tooltip">
              {fullClinicName}
            </div>
          )}
        </div>
      </h1>
    </div>
  </header>

  );
};

export default Header;
