import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

// Enable cookies for axios
axios.defaults.withCredentials = true;

const NavigationMenu = ({ clinicId }) => {
  const navigate = useNavigate(); // Get the navigate function for navigation
  const [config, setConfig] = useState(null); // config for APIs

  // For API config.json
  useEffect(() => {
    // Function for reading config.json
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    // Read API config.json
    fetchConfig();
  }, []); // Empty dependency array ensures this runs once on mount

  // Logout function to handle the API call and redirect
  const handleLogout = async () => {
    try {
      // const apiUrl = process.env.REACT_APP_API_LOGOUT; // read from .env
      const apiUrl = config.API_LOGOUT;
      const response = await axios.post(
        apiUrl,
        {}, // POST request body (if any), empty object for now
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 200) {
        throw new Error('Logout failed');
      }

      // Clear session storage or any other session-related data
      localStorage.removeItem('session');
      sessionStorage.removeItem('session');
      // localStorage.removeItem("app-uuid");
      // Optionally clear cookies
      document.cookie = 'session=; Max-Age=-99999999;';
      // On success, navigate to the landing page
      const landingPageUrl = `/${clinicId}`; // Modify based on your needs
      navigate(landingPageUrl); // Use navigate for redirection
    } catch (error) {
      console.error('Error during logout:', error);
      // Optionally, handle error by showing a message or fallback
    }
  };

  return (
    <nav className="navigation-menu">
      <ul className="menu-items">
        {/* Apply the active "Get Care" style to Home */}
        <li className="menu-item menu-item-care">
          <div className="care-icon-wrapper">
            <img
              src="/assets/images/home.png"
              alt=""
              className="home-icon"
            />
          </div>
          <span className="care-text">Home</span>
        </li>

        {/* Change "Get Care" to inactive */}
        <li className="menu-item menu-item-health">
          <img
            src="/assets/images/health.svg"
            alt=""
            className="menu-icon"
          />
          <span className="menu-text">Health</span>
        </li>

        <li className="menu-item menu-item-care">
          <img
            src="/assets/images/get-care.svg"
            alt=""
            className="menu-icon"
          />
          <span className="menu-text">Get Care</span>
        </li>

        <li className="menu-item menu-item-visits">
          <img
            src="/assets/images/visits.svg"
            alt=""
            className="menu-icon"
          />
          <span className="menu-text">Visits</span>
        </li>

        <li className="menu-item menu-item-settings" onClick={handleLogout}>
          <img
            src="/assets/images/settings.svg"
            alt=""
            className="menu-icon"
          />
          <span className="menu-text">Logout</span>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationMenu;
