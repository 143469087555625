import React from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonContainer = ({ id, logoUrl, emergencyMessage, clinicName, currentPage, serviceId, visitsData, fullClinicName }) => { // Accept logoUrl as a prop
  const navigate = useNavigate();

  const handleLoginClick = () => {
    // Navigate to `/login` and pass both id and logoUrl as state
    navigate(`/login`, { state: { id, logoUrl, emergencyMessage, clinicName, currentPage, serviceId, visitsData, fullClinicName } });
  };

  const handleRegisterClick = () => {
    // Navigate to `/register` and pass both id and logoUrl as state
    navigate(`/register`, { state: { id, logoUrl, emergencyMessage, clinicName, fullClinicName, currentPage, serviceId, visitsData } });
  };

  return (
    <div className="button-container">
      <button className="btn btn-register" onClick={handleRegisterClick}>
        Register
      </button>
      <button className="btn btn-login" onClick={handleLoginClick}>
        Login
      </button>
    </div>
  );
};

export default ButtonContainer;
