// src/AnonymousScreen/components/ServiceDetailPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import Header from './Header';
import ButtonContainer from './ButtonContainer';
import 'survey-core/defaultV2.min.css';

const ServiceDetailPage = () => {
  const { serviceId, clinicId } = useParams();
  const { state } = useLocation(); // Get state passed through navigation
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [formId, setFormId] = useState(null);
  const [error, setError] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [serviceIdFromData, setServiceIdFromData] = useState(null); // To store serviceId from API
  const [config, setConfig] = useState(null); // config for APIs
  const currentPage = 'service';

  // For API config.json
  useEffect(() => {
    // Function for reading config.json
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    // Read API config.json
    fetchConfig();
  }, []); // Empty dependency array ensures this runs once on mount

  // Retrieve logoUrl and currentPage from state
  const logoUrl = state?.logoUrl;
  const emergencyMessage = state?.emergencyMessage;
  const clinicName = state?.clinicName;
  const savedPage = state?.currentPage || 0; // Saved page index or default to 0
  const fullClinicName = state?.fullClinicName;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const landingUrl = process.env.REACT_APP_API_SERVICES; // read from env
        const landingUrl = config?.API_SERVICES; // Read from config.json
        const apiUrl = `${landingUrl}clinicId=${clinicId}&serviceId=${serviceId}`;
        
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        // Fetch the serviceId directly from the API response
        const fetchedServiceId = data?.RESPONSE?.serviceInfo?.clinic?.serviceId;
        setServiceIdFromData(fetchedServiceId); // Store serviceId from API

        // Set the form data from the API response
        setFormData(data.RESPONSE.serviceInfo.clinic.serviceQuestionnaire.form); // Get form JSON
        setFormId(data.RESPONSE.serviceInfo.clinic.serviceQuestionnaire.id); // Get form JSON

        // Check if serviceId is missing
        if (!fetchedServiceId) {
          console.error('Service ID is missing in API response.');
        }
      } catch (err) {
        console.error('Error fetching service details:', err);
        setError('Failed to fetch service details. Please try again later.');
      }
    };

    if (config?.API_SERVICES) {
      fetchData();
    }
  }, [serviceId, clinicId, config?.API_SERVICES]); // Depend on serviceId, clinicId, and config.API_SERVICES

  useEffect(() => {
    if (formData) {
      const surveyInstance = new Model(formData);
      setSurvey(surveyInstance);

      // Restore saved page after initialization
      surveyInstance.onAfterRenderSurvey.add(() => {
        surveyInstance.currentPageNo = savedPage; // Apply the saved page
      });

      // Log current page changes for debugging
      surveyInstance.onCurrentPageChanged.add(() => {
        // const currentPage = surveyInstance.currentPageNo;
      });

      // Handle survey completion
      surveyInstance.onComplete.add(async (result) => {
        try {
          // Prepare the POST data (Requirement 2)
          const storedUUID = localStorage.getItem("app-uuid");
          
          const postData = {
            deviceId: storedUUID,
            formId: formId,  // Use the serviceId fetched from API response
            answers: Object.entries(result.data).map(([question, answer]) => ({
              question,
              answer,
            })),
          };

          console.log(JSON.stringify(postData));

          // const apiUrl = process.env.REACT_APP_API_VISITS; // read from .env
          const apiUrl = config?.API_VISITS; // Read from config.json

          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch visits: ${response.statusText}`); 
          }

          const responseData = await response.json();

          // Navigate to VisitListsPage (Requirement 3)
          navigate('/visit-lists', {
            state: {
              clinicId,
              visitsData: responseData.RESPONSE.serviceInfo.clinic.visitTypeList,
              logoUrl,
              emergencyMessage,
              clinicName,
              fullClinicName
            },
          });
        } catch (error) {
          console.error('Error handling survey completion:', error);
          setError('Failed to submit survey. Please try again later.');
        }
      });
    }
  }, [formData, formId, savedPage, clinicId, serviceIdFromData, logoUrl, emergencyMessage, navigate, config?.API_VISITS, clinicName, fullClinicName]); // Add config.API_VISITS



  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!formData) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
        <div className="loading-dot">.</div>
      </div>
    );
  }

  return (
    <section className="container">
      {/* Header Section with Logo */}

      <Header 
        logoUrl={logoUrl} 
        clinicName={clinicName} // Pass clinicName as a string
        fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      {/* SurveyJS Form */}
      <main className="main-content">
        {survey && <Survey model={survey} />}
      </main>

      {/* Footer Buttons */}
      <footer>
        <ButtonContainer 
          id={clinicId} 
          logoUrl={logoUrl} 
          emergencyMessage={emergencyMessage} 
          clinicName={clinicName}
          currentPage={currentPage}
          serviceId={serviceId}
          fullClinicName={fullClinicName}
        />
      </footer>
    </section>
  );
};

export default ServiceDetailPage;
