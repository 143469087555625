// src/PatientScreen/components/NotificationsScreen.js

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationMenu from "./NavigationMenu";

const NotificationsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { notificationsData, clinicId } = location.state || {};

  if (!notificationsData) {
    return <p>No notifications available.</p>; // Fallback for missing data
  }

  // Format date to local time
  const formatDateToLocal = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Handle back navigation
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <section className="container">
        <div className="notifications-container">
          {/* Header */}
          <div className="notifications-header">
            <button className="back-button" onClick={handleBack}>
              ← Back
            </button>
            <h1>Notifications</h1>
          </div>

          {/* Notifications List */}
          <div className="notifications-list">
            {notificationsData.map((notification) => (
              <div
                key={notification.notificationId}
                className={`notification-card ${
                  notification.readFlag ? "read" : "unread"
                }`}
              >
                <p className="notification-text">{notification.text}</p>
                <p className="notification-date">
                  {formatDateToLocal(notification.date)}
                </p>
                <p className="notification-sender">From: {notification.sender.name}</p>
              </div>
            ))}
          </div>
        </div>
        {/* Navigation Menu */}
        <NavigationMenu clinicId={clinicId} />
    </section>
  );
};

export default NotificationsScreen;
