import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import ButtonContainer from './ButtonContainer';

const VisitListsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const visitsData = useMemo(() => state?.visitsData || [], [state?.visitsData]);
  const logoUrl = state?.logoUrl;
  const emergencyMessage = state?.emergencyMessage;
  const clinicName = state?.clinicName;
  const fullClinicName = state?.fullClinicName;
  const id = state?.clinicId;
  const currentPage = 'visits';
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (visitsData.length === 1) {
      setShowPopup(true);

      const timeout = setTimeout(() => {
        setShowPopup(false);
        const visit = visitsData[0];
        navigate('/login', {
          state: {
            id,
            emergencyMessage,
            visitId: visit.visitId,
            visitName: visit.visitName,
            logoUrl,
            clinicName,
            fullClinicName,
            currentPage: 'visit',
          },
        });
      }, 3000);

      return () => clearTimeout(timeout); // Cleanup timeout
    }
  }, [clinicName, emergencyMessage, fullClinicName, id, logoUrl, navigate, visitsData]);

  const handleCardClick = (visitId, visitName) => {
    navigate('/login', {
      state: {
        id,
        logoUrl,
        emergencyMessage,
        clinicName,
        currentPage: 'visit',
        visitId,
        visitName,
        fullClinicName,
      },
    });
  };

  return (
    <section className="container">
      {/* Header Section with Logo */}
      <header className="header">
        <Header
          logoUrl={logoUrl}
          clinicName={clinicName} // Pass clinicName as a string
          fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
        />
      </header>
      <div className="info-message">Please select from these available visits</div>
      {/* Visit List as Cards */}
      {visitsData.map((visit) => (
        <div
          key={visit.visitId}
          className="visit-card"
          onClick={() => handleCardClick(visit.visitId, visit.visitName)} // Attach the click handler with parameters
        >
          <div className="visit-title">{visit.visitName}</div>
          <div className="visit-subtitle">
            {visit.allowOnDemand ? 'Available Now' : 'Appointment Only'}
          </div>
        </div>
      ))}

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-icon">🎉</div>
            <p>{`${visitsData[0]?.visitName} selected. Redirecting to Login screen ...`}</p>
          </div>
        </div>
      )}

      {/* Footer Buttons */}
      <footer>
        <ButtonContainer
          id={id}
          emergencyMessage={emergencyMessage}
          logoUrl={logoUrl}
          clinicName={clinicName}
          currentPage={currentPage}
          visitsData={visitsData}
          fullClinicName={fullClinicName}
        />
      </footer>
    </section>
  );
};

export default VisitListsPage;
