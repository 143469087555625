import React from 'react';

const AppointmentCard = ({ nextAppointment, onClick, isArrow, switchArrow }) => {
  const localDate = new Date(nextAppointment.dateTime);
  const now = new Date();
  const timeDifference = localDate - now; // Difference in milliseconds
  const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutesDiff = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  let titleText = "Upcoming Appointment";
  let buttonText = "Send a message";

  let arrow = '/assets/images/down-arrow.png';
  if(switchArrow)
  {
    arrow = '/assets/images/right-arrow.png';
  }

  if (timeDifference > 24 * 60 * 60 * 1000) {
    titleText = "Upcoming Appointment";
    buttonText = "Send a message";
  } else if (timeDifference <= 24 * 60 * 60 * 1000 && timeDifference > 5 * 60 * 1000) {
    titleText = `Visit will start in ${hoursDiff}h:${minutesDiff}m`;
    buttonText = "Send a message";
  } else if (timeDifference <= 5 * 60 * 1000 && timeDifference > 0) {
    titleText = "Visit is about to start";
    buttonText = "Enter now";
  } else if (timeDifference <= 0) {
    titleText = "Visit has started";
    buttonText = "Re-enter now";
  }

  const formattedDate = localDate.toLocaleDateString('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });
  const formattedTime = `${localDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  })} - ${new Date(localDate.getTime() + 60 * 60 * 1000).toLocaleTimeString(
    'en-US',
    { hour: '2-digit', minute: '2-digit' }
  )}`;
  const appointmentType = nextAppointment.serviceType;

  return (
    <article className="appointment-card" onClick={onClick}>
      {/* Icon and Title Section */}
      <div className="card-header">
        <div className="icon-container">
          <img
            src="/assets/images/clock.svg"
            alt="Clock Icon"
            className="clock-icon"
          />
        </div>
        <h3 className="appointment-title">{titleText}</h3>
        {isArrow && (
        <img
          src={arrow}
          alt="Right Arrow"
          className="right-arrow"
        />
        )}
      </div>

      {/* Details Section */}
      <div className="card-content">
        <p className="appointment-service">{appointmentType}</p>
        <p className="appointment-date">{formattedDate}</p>
        <div className="time-and-button">
          <p className="appointment-time">{formattedTime}</p>
          <button className="message-button">{buttonText}</button>
        </div>
      </div>
    </article>
  );
};

export default AppointmentCard;
