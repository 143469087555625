import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/css/register.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios'; // Import axios
import Header from './Header';
import GoogleAddressAutocomplete from "./GoogleAddressAutocomplete";


const RegisterPage = () => {
  // State for form fields
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState('');
  
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const [step, setStep] = useState(1); // Track the step
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [termsContent, setTermsContent] = useState(''); // State for terms and conditions content
  const [scrollPosition, setScrollPosition] = useState(0); // Added scroll position state
  const [config, setConfig] = useState(null); // config for APIs
  
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress); // Update address from GoogleAddressAutocomplete
  };

  // For API config.json
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, []); // Empty dependency array ensures this runs once on mount

  // Routing
  const navigate = useNavigate();
  const location = useLocation();
  const { id, logoUrl, emergencyMessage, clinicName, fullClinicName, visitsData, visitId, visitName, currentPage, serviceId } = location.state || {};

  // Handle form field changes
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleDateChange = (date) => setDob(date);
  const handleGenderChange = (e) => setGender(e.target.value);

  // Handle back navigation
  const handleBackButton = () => {
    if (step === 1) {
      navigate(-1); // Go back to the previous page if on step 1
    } else if (step === 6) {
      navigate('/login', { state: { id, logoUrl, emergencyMessage, clinicName, fullClinicName, visitsData, visitId, visitName, currentPage, serviceId } });
    } else {
      setStep(step - 1); // Otherwise, go back to the previous step
    }
  };

  // Get progress percentage
  const getProgress = () => {
    switch (step) {
      case 1:
        return 20;
      case 2:
        return 40;
      case 3:
        return 60;
      case 4:
        return 80;
      default:
        return 100;
    }
  };

  const handleFinalSubmit = () => {
    navigate('/login', { state: { id, logoUrl, emergencyMessage, clinicName, fullClinicName, visitsData, visitId, visitName, currentPage, serviceId } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    // Validate form based on the step
    if (step === 1) {
      if (!email || !phone) {
        setErrorMessage('Please fill out both Email and Phone fields.');
        return;
      }
    }

    if (step === 2 && (!firstName || !lastName)) {
      setErrorMessage('Please fill out both First Name and Last Name fields.');
      return;
    }

    if (step === 3) {
      const gender = document.getElementById("gender").value;
      if (!dob || !gender) {
        setErrorMessage('Please fill out both Date of Birth and Gender fields.');
        return;
      }
    }

    if (step === 4) {
        if (!address.street || !address.city || !address.state || !address.zip || !address.country) {
          setErrorMessage('Please fill out all address fields.');
          return;
        }
    }

    if (step < 5) {
      setStep(step + 1); // Move to next step
    } else if (step === 5) {
      const userData = {
        email,
        phone_number: phone,
        first_name: firstName,
        last_name: lastName,
        gender,
        birthdate: dob ? dob.toISOString().split('T')[0] : '', // Convert to YYYY-MM-DD
        address: {
          street: address.street,
          city: address.city,
          state: address.state,
          zip_code: address.zip,
          country: address.country,
        }
      };

      try {
        console.log(userData);
        const apiUrl = config.API_REGISTER;
        const response = await axios.post(apiUrl, userData, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        const { RESPONSE_CODES } = response.data;
        
        if (RESPONSE_CODES.includes('U_REGISTERED')) {
          setStep(6); // Successfully registered, move to step 6
        } else {
          setErrorMessage('Registration failed. Please try again.');
        }
      } catch (error) {
        setErrorMessage('An error occurred while registering. Please try again later.');
        console.error('Error during registration:', error);
      }
    }
  };

  // Modal handling
  const openModal = async () => {
    try {
      const apiUrl = config.API_TERMS;
      const response = await axios.get(apiUrl, {
        withCredentials: true, // Enable sending cookies with requests
      });

      const clinicTerms = response.data.RESPONSE?.tc;
      const termsAndConditionsHTML = (
        <div>
          {clinicTerms.map((section, index) => (
            <div key={index}>
              <h4>{section.heading}</h4>
              <p dangerouslySetInnerHTML={{ __html: section.paragraph }} />
            </div>
          ))}
        </div>
      );
      setTermsContent(termsAndConditionsHTML);
    } catch (error) {
      setTermsContent('Failed to load Terms & Conditions. Please try again later.');
      console.error('Error fetching Terms & Conditions:', error);
    }
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setStep(5); // Ensure the step stays at 5 if the modal is closed
  };

  const handleScroll = (event) => {
    const scrollPosition = event.target.scrollTop;
    setScrollPosition(scrollPosition);
  };

  return (
    
    <section className="container">
      {/* Your existing JSX code... */}
      <header className="header-wrapper">
        <div className="header-content">
          <img
            src="assets/images/back.svg"
            alt="Back"
            className="header-icon"
            onClick={handleBackButton}
          />
          <h1 className="header-title">Sign Up</h1>
          <img
            src="assets/images/close.svg"
            alt="Landing"
            className="header-icon"
            onClick={() => navigate(`/${id}`)}
          />
        </div>
      </header>

      <Header 
        logoUrl={logoUrl} // Pass logoUrl to Header
        clinicName={clinicName} // Pass clinicName as a string
        fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      <main className="main-content">
      
        <form className="form-container" onSubmit={handleSubmit}>
          {/* Step 1: Email and Phone */}
          {step === 1 && (
            <>
              <h2 className="form-title">What is your email and phone number?</h2>
              <div className="input-wrapper">
                <label htmlFor="email" className="input-label">Email</label>
                <input
                  type="email"
                  id="email"
                  className="input-field"
                  value={email}
                  onChange={handleEmailChange}
                  aria-label="Email"
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="phone" className="input-label">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  className="input-field phone-input"
                  value={phone}
                  onChange={handlePhoneChange}
                  aria-label="Phone Number"
                />
              </div>
            </>
          )}

          {/* Step 2: Name Fields */}
          {step === 2 && (
            <>
              <h2 className="form-title">What is your first and last name?</h2>
              <div className="input-wrapper">
                <label htmlFor="first_name" className="input-label">First name</label>
                <input
                  type="text"
                  id="first_name"
                  className="input-field"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  aria-label="First name"
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="last_name" className="input-label">Last name</label>
                <input
                  type="text"
                  id="last_name"
                  className="input-field"
                  value={lastName}
                  onChange={handleLastNameChange}
                  aria-label="Last name"
                />
              </div>
            </>
          )}


          {/* Step 3: Date of Birth and Gender */}
          {step === 3 && (
            <>
              <h2 className="form-title">What is your date of birth and gender?</h2>
              <div className="input-wrapper">
                <label htmlFor="dob" className="input-label">Date Of Birth</label>
                <DatePicker
                  selected={dob}
                  onChange={handleDateChange}
                  className="input-field"
                  placeholderText="Select your date of birth"
                  dateFormat="MMM dd, yyyy" // Show date as "Dec 04, 2024"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  scrollableYearDropdown
                  yearDropdownItemNumber={15} // Option to scroll through years
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="gender" className="input-label">Gender</label>
                <select 
                id="gender" 
                className="input-field" 
                aria-label="Gender"
                selected={gender}
                onChange={handleGenderChange}
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="unspecified">Unspecified</option>
                </select>
              </div>
            </>
          )}

          {/* Step 4: Address Fields */}
          {step === 4 && (
          <GoogleAddressAutocomplete address={address} onAddressChange={handleAddressChange} />
          )}
          
          {/* Step 5: Terms and Conditions */}
          {step === 5 && (
            <section className="accept-terms-container">
              <h2 className="accept-terms-title">Terms & Conditions</h2>
              <div className="accept-terms-content">
                <p className="accept-terms-text">
                  By clicking "Accept & Create Account" you are agreeing to {fullClinicName}
                </p>
                <p>
                  <button type='button' onClick={openModal} className="back-link" aria-label="Terms and Conditions"><span>Terms and Conditions</span></button>
                </p>
              </div>
            </section>
          )}

          {/* Step 6: Temporary Password */}
          {step === 6 && (
          <section className="temp-password-container">
            
              <h1 className="temp-password-title">Your account was successfully created with a temporary password, check your email & proceed from there to set your permanent password.</h1>
              <div className="icon-container-register" role="img" aria-label="Featured success icon">
                <img
                    loading="lazy"
                    src="assets/images/done.svg"
                    className="icon-image"
                    alt=""
                />
              </div>
              <div className="temp-password-content">
                <header className="temp-password-header">
                  <p className="temp-password-text">Temporary password was sent to</p>
                </header>
                <p className="temp-password-email">{email}</p>
                <div className="temp-password-instructions">
                  <p className="temp-password-text">
                    Please check your email to complete registration.
                  </p>
                  <p className="temp-password-note">
                    In case you didn't receive your email, please check your spam folder. To get another notification, please use Forgot Password.
                  </p>
                </div>
              </div>
            </section>
          )}


          {/* Modal for Terms and Conditions */}
          {isModalOpen && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="terms-modal" role="dialog" aria-labelledby="terms-title">
                <div className="modal-content">
                  <img
                    loading="lazy"
                    src="/assets/images/terms.svg"
                    className="terms-icon"
                    alt="Terms and Conditions Icon"
                  />
                  <div className="terms-header">
                    <h2 id="terms-title" className="terms-title">Terms & Conditions</h2>
                    <div className="terms-body">
                      <div id="terms-text" className="terms-text">
                      {termsContent}
                      </div>
                      <div className="scroll-container" onScroll={handleScroll}>
                        <div className="scroll-track">
                          <div className="scroll-thumb" role="scrollbar" aria-controls="terms-text" aria-valuenow={scrollPosition} tabIndex="0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="close-button-wrapper">
                    <button type='button' onClick={closeModal} className="close-button" aria-label="Close terms and conditions">Close</button>
                  </div>
                </div>
              </div>
            </div>
          )}


          {/* Error Message */}
          {errorMessage && <div className="error-message">{errorMessage}</div>}

        </form>
      </main>

      {/* Footer for submission */}
      {step !== 6 && (
        <footer className="footer-wrapper">
          <button
            type="button"
            className="submit-button"
            onClick={handleSubmit}
          >
            {step === 5 ? 'Accept and Create Account' : 'Next'}
          </button>
          <div className="progress-container">
            <div className="progress-bar">
              <div
                className="progress-fill"
                style={{ width: `${getProgress()}%` }}
              ></div>
            </div>
            <span className="progress-text">{getProgress()}%</span>
          </div>
        </footer>
      )}

      {/* Footer for submission */}
      {step === 6 && (
        <footer className="footer-wrapper">
            <button
                className="submit-button"
                type="button"
                onClick={handleFinalSubmit}
              >
                Done
            </button>
        </footer>
      )}
      
    </section>
    
  );
};

export default RegisterPage;
