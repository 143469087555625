// src/PatientScreen/DashboardScreen.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate for redirecting
import Header from './components/Header';
import MainContent from './components/MainContent';
import NavigationMenu from './components/NavigationMenu';
import UserGreeting from './components/UserGreeting';
import AppointmentCard from './components/AppointmentCard';
import './assets/css/style.css';

const DashboardScreen = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate to redirect
  const dashboardData = location.state?.dashboardData;

  // Always call useState first
  const [isExpanded, setIsExpanded] = useState(false); // State for managing expanded view

  // Redirect logic if dashboardData is not available
  useEffect(() => {
    if (!dashboardData) {
      const clinicId = dashboardData?.RESPONSE?.clinicInfo?.clinicId;
      if (clinicId) {
        navigate(`/${clinicId}`); // Redirect to the clinic's landing page
      }
    }
  }, [dashboardData, navigate]);

  // If dashboardData is not available, render nothing while redirecting
  if (!dashboardData) {
    return null; // Prevent the page from rendering prematurely
  }

  // Extract common values
  const clinicData = dashboardData?.RESPONSE?.clinicInfo;
  const userInfo = dashboardData?.RESPONSE?.userInfo; // User info is the patient data
  const landingForm = dashboardData?.RESPONSE?.clinicLandingInfo?.landingForm?.form;

  const clinicId = clinicData?.clinicId;
  const logoUrl = clinicData?.smallLogo;
  const emergencyMessage = clinicData?.emergencyMsg;
  const clinicName = clinicData.clinicName; // Trim lengthy names
  const fullClinicName = location.state?.fullClinicName;

  const patientFirstName = userInfo?.patientFirstName;
  const patientAvatar = userInfo?.patientAvatar;
  const patientNotifications = userInfo?.patientNotifications;
  const userNextAppointment = userInfo?.patientAppointments;

  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState); // Toggle the expanded view state
  };

  return (
    <section className="container">
      <Header 
        emergencyMessage={emergencyMessage}
        logoUrl={logoUrl} // Pass logoUrl to Header
        clinicName={clinicName} // Pass clinicName as a string
        fullClinicName={fullClinicName} // Pass the full clinic name for tooltip
      />

      <section className="appointment-container">
        <UserGreeting
          patientName={patientFirstName}
          avatar={patientAvatar}
          patientNotifications={patientNotifications}
          clinicId={clinicId}
          welcome={true}
        />

        {/* Always show the first appointment (if available) */}
        {userNextAppointment && userNextAppointment.length > 0 && (
          <AppointmentCard 
            nextAppointment={userNextAppointment[0]} 
            onClick={toggleExpand} 
            isArrow={true}
            switchArrow={!isExpanded}
          />
        )}

        {/* Expanded View: Show all appointments starting from the second one */}
        {isExpanded && userNextAppointment && userNextAppointment.length > 1 && userNextAppointment.slice(1).map((appointment) => (
          <AppointmentCard 
            key={appointment.visitId} 
            nextAppointment={appointment} 
          />
        ))}
      </section>

      <MainContent 
        clinicName={clinicName}
        services={landingForm} 
        clinicData={clinicData}
        userInfo={userInfo} // Pass user info as patient data
        fullClinicName={fullClinicName}
      />

      <NavigationMenu clinicId={clinicId} />
    </section>
  );
};

export default DashboardScreen;
